<template>
    <div class="reportParent" v-if="reportData&&reportOptions">
        <div ref='nowHeight' class="flex-between findFixed">
            <el-row class="inputBox">
                <InputCard 
                    dataname='mediaId'
                    cardname="媒体" 
                    :dataList="reportOptions.medias"
                    @passChecked="handleAddCondition"
                />
                <InputCard 
                    dataname='advplaceId'
                    cardname="广告位" 
                    :dataList="reportOptions.advplace"
                    @passChecked="handleAddCondition"
                />
                <InputCard 
                    dataname='showType'
                    cardname="广告类型" 
                    :dataList="reportOptions.showType"
                    @passChecked="handleAddCondition"
                />
                <InputDateCheck @passDate='handleDate'/> 
            </el-row>
            <div class="enterBox">
                <el-button type="primary" @click="handleReqData">查询</el-button>
            </div>
        </div>
        <div class="mainBody">
            <div class="helpDiv"></div>
            <div class="showDatas">
                <div class="titleReport">
                    <div>
                        <Help title="整体报表" >
                            <p class="helpText">
                                您的昨日数据报告在今日23：59（北京时间）前可能有部分没有纳入统计，
                                <br/>我们将在未来2-3天内为您提供完整的数据报告，因为广告统计可能会有延时。
                                <br/>请注意，所有的预估收益为近似值，并不代表最终结果，
                                <br/>最终确认的收益数字在您每个月结算信息中反映。如有疑问请联系专属客服。
                            </p>
                        </Help>
                    </div>
                    <div class="reportDetail">
                        <div v-for="item,index in titleReportList" :key='index'>
                            <div>
                                <img :src="dataImgsList[index]" :alt="item">
                            </div>
                            <div>
                                <p>{{item}}</p>
                                <p>{{reportEchart.math[titleValueKey[index]]}}{{index===2?'%':''}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="reportLineChart">
                    <div class="chartTitle">综合折线图</div>
                    <div class="chartCheck">
                        <!-- 必须给两个Id配置,用以点击区域外隐藏option -->
                        <CheckOneOption 
                            selectId="reportSelectOne" 
                            optionId="reportOptionOne" 
                            :lineShowList="titleReportList"
                            @passtitle="handleChangeTitle"
                            nowposition='left'
                            :checkedValue="leftCheckedValue" 
                        />
                        <CheckOneOption 
                            selectId="reportSelectTwo" 
                            optionId="reportOptionTwo" 
                            :checkedValue="rightCheckedValue" 
                            mackColor="#DCAA28"
                            nowposition="right"
                            :lineShowList="titleReportList"
                            @passtitle="handleChangeTitle"
                        />
                    </div>
                    <div class="chartBox">
                        <transition
                            enter-active-class="animate__animated animate__fadeIn" 
                            leave-active-class="animate__animated animate__fadeOut"
                        >
                            <Echart :option="lineOption" v-if='reshowEchart'/>
                        </transition>
                    </div>
                </div>
                <div class="reportData">
                    <div>数据报表</div>
                    <FinanceData 
                        :tableData="reportData.lists"
                        @changeSize="handleChangeSize"
                        @changePage="handleChangePage"
                        @passGroupBy="handleAddGroup"
                        :total='reportData.total'
                        :returnPage1='returnPage1'
                        :reqData='reqData'
                    />
                </div>
            </div>
            </div>
    </div>
</template>
<script>
    import InputCard from '../components/InputCard.vue'
    import InputDateCheck from '../components/InputDateCheck.vue'
    import FinanceData from '../components/FinanceData.vue'
    import Echart from '../components/Echart.vue'
    import Help from '../components/Help.vue'
    import CheckOneOption from '../components/CheckOneOption.vue'
    import {mapGetters, mapState} from 'vuex'
    export default {
        components: {
            InputCard,InputDateCheck,FinanceData,Echart,Help,CheckOneOption
        },
        data(){
            return {
                titleReportList:['曝光量','点击量','点击率','预估收入'],
                titleValueKey:["ad_show","ad_click","click_rate","money"],
                dataImgsList:[
                    require('../assets/imgs/baoguang.png'),
                    require('../assets/imgs/click.png'),
                    require('../assets/imgs/clickpoint.png'),
                    require('../assets/imgs/shouru.png')
                ],
                returnPage1:0,
                reshowEchart:true,
                reqData:{
                    dtBegin:'',
                    dtEnd:'',
                    page:'1',
                    pageSize:'10',
                    mediaId:[],
                    showType:[],
                    advplaceId:[]
                }
            }
        },
        computed:{
            ...mapState({
                reportData:state=>state.dataReport.reportData,
                reportOptions:state=>state.dataReport.reportOptions,
                reportEchart:state=>state.dataReport.reportEchart,
                leftCheckedValue:state=>state.dataReport.leftLine,
                rightCheckedValue:state=>state.dataReport.rightLine,
            }),
            ...mapGetters({
                lineOption:'dataReport/newLineOption'
            })
        },
        watch:{
            lineOption(val){
                this.triggerReshowEchart()
            }
        },
        methods:{
            handleAddCondition(arg) {
                let arr
                if(arg.title === 'mediaId' || arg.title==='advplaceId'){
                    arr =  arg.value.map(item=>item.split('-')[0])
                    this.reqData[arg.title] = arr
                }else if(arg.title === 'showType'){
                    arr =  arg.value.map(item=>item.split('-')[0])
                    this.reqData[arg.title] = arr
                }
            },
            handleDate(arg){
                this.reqData.dtBegin = arg[0]
                this.reqData.dtEnd = arg[1]
            },
            handleChangeSize(val){
                this.reqData.pageSize = val
                this.$store.dispatch('dataReport/reportDataAct',this.reqData)
            },
            handleChangePage(val){
                this.reqData.page = val
                this.$store.dispatch('dataReport/reportDataAct',this.reqData)
            },
            handleAddGroup(arg){
                this.reqData.page = '1'
                this.reqData.groupBy = arg
                this.$store.dispatch('dataReport/reportDataAct',this.reqData)
                this.returnPage1++
            },
            handleReqData(){
                this.reqData.page = '1'
                this.returnPage1++
                sessionStorage.setItem('searchDate',JSON.stringify(this.reqData))
                this.$store.dispatch('dataReport/reportDataAct',this.reqData)
                this.$store.dispatch('dataReport/reportEchartAct',this.reqData)
            },
            handleChangeTitle(arg){//改变折现图显示标题
                this.$store.commit('dataReport/changeLineMut',arg)
            },
            triggerReshowEchart(){
                this.reshowEchart = false
                setTimeout(()=>{
                    this.reshowEchart = true
                },300)
            }
        },
        created(){
            let checkedGroup = sessionStorage.getItem('groupBy')
            if(checkedGroup){
                this.reqData.groupBy = JSON.parse(checkedGroup)
            }
        },
    }
</script>

<style lang="less" scoped>
    .reportParent{
        position: relative;
        background: #F5F7F9;
        height: 100%;
        overflow: auto;
    }
    .findFixed{
        padding:20px;
        box-sizing: border-box;
        border-bottom:1px solid #dde;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: white;
        z-index:99;
        .inputBox{
            width:90%;
            .col-box{
                margin-right: 20px;
            }
        }
        .enterBox{
            width:10%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
        }
    }
    .mainBody{
        height: 100%;
        overflow: auto;
        .helpDiv{
            height: 88px;
        }
    }
    .showDatas{
        padding:20px;
        padding-top: 0;
        .helpText{
            font-size: 12px;
            color: #696969;
            padding: 0 20px;
            width: 430px;
            line-height: 16px;
            a{
                color: #28A5DC;
            }
        }
        .titleReport{
            background: white;
            padding:20px;
            border-radius: 4px;
            margin-top:20px;
            .reportDetail{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin:36px 0;
                >div{
                    margin-right: 12%;
                    display: flex;
                    >div:nth-child(1){
                        width: 46px;
                        height:46px;
                        margin-right: 28px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    >div:nth-child(2){
                        p:nth-child(1){
                            font-size: 14px;
                            color: #8F9196;
                            line-height: 20px;
                        }
                        p:nth-child(2){
                            font-size: 24px;
                            font-weight: 600;
                            color: #333333;
                            line-height: 33px;
                        }
                    }
                }
            }
        }
    }
   .reportLineChart{
        margin-top:28px;
        padding:20px 20px 30px;
        padding-top:30px;
        box-sizing: border-box;
        height: 490px;
        background: white;
        border-radius:6px;
        .chartTitle{
            font-size: 16px;
            font-weight: 900;
        }
        .chartCheck{
            display: flex;
            justify-content: flex-start;
            margin-top:16px;
        }
        .chartBox{
            height:330px;
            margin-right:40px;
        }
    }
    .reportData{
        margin-top: 28px;
        background: white;
        padding:20px;
        >div:nth-child(1){
            font-weight: 900;
        }
    }
</style>