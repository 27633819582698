<template>
<div>
    <el-row class="dataRow" v-if='isFilter'>
        <el-col :span="15" class="margin-tb">
            <span>拆解维度：</span>  
            <el-checkbox v-model="witchChecked.one" disabled>日期</el-checkbox>
            <el-checkbox v-model="witchChecked.two" @change="handleChangeDeep">媒体</el-checkbox>
            <el-checkbox v-model="witchChecked.three" @change="handleChangeDeep">广告位</el-checkbox>
            <el-checkbox v-model="witchChecked.four" @change="handleChangeDeep">广告类型</el-checkbox>
        </el-col>
        <el-col :span="1" :offset="8" class="downLoadBtn">
            <el-button class="btn" size="small" @click="exportExcel">下载</el-button>
        </el-col>
    </el-row>
    <el-table
        :data="newTableData"
        id="out__table"
        border
        stripe
        :max-height="480"
        style="width: 100%"
        >
        <el-table-column
        prop="moneyDate"
        label="日期"
        v-if="witchChecked.one"
        >
        </el-table-column>
        <el-table-column
        prop="media_name"
        label="媒体"
        v-if="witchChecked.two"
        >
        </el-table-column>
        <el-table-column
        prop="advplace_name"
        v-if="witchChecked.three"
        label="广告位"
        >
        </el-table-column>
        <el-table-column
        prop="adType"
        v-if="witchChecked.four"
        label="广告类型"
        >
        </el-table-column>
        <el-table-column
        prop="uv_total"
        label="请求用户数"
        >
        </el-table-column>
        <el-table-column
        prop="request_total"
        label="广告请求"
        >
        </el-table-column>
        <el-table-column
        prop="request_back"
        label="请求成功数"
        >
        </el-table-column>
        <el-table-column
        prop="request_rate"
        label="填充率"
        >
        </el-table-column>
        <el-table-column
        prop="show_total"
        label="曝光数"
        >
        </el-table-column>
        <el-table-column
        prop="show_rate"
        label="曝光率"
        >
        </el-table-column>
        <el-table-column
        prop="click_total"
        label="点击数"
        >
        </el-table-column>
        <el-table-column
        prop="click_rate"
        label="点击率"
        >
        </el-table-column>
        <el-table-column
        prop="cpm"
        label="eCPM（元）"
        >
        </el-table-column>
         <el-table-column
        prop="money_total"
        label="预计收入（元）"
        >
        </el-table-column>
    </el-table>
    <Paging
      :dataList='tableData' 
      @changeSize='changeSize'
      @changePage='changePage'
      :total='total'
      :returnPage1='returnPage1'
    />
    <el-table
        :data="allTableData"
        id="getout__table"
        border
        stripe
        :max-height="480"
        style="width: 100%"
        v-show='false'
        >
        <el-table-column
        prop="moneyDate"
        label="日期"
        v-if="witchChecked.one"
        >
        </el-table-column>
        <el-table-column
        prop="media_name"
        label="媒体"
        v-if="witchChecked.two"
        >
        </el-table-column>
        <el-table-column
        prop="advplace_name"
        v-if="witchChecked.three"
        label="广告位"
        >
        </el-table-column>
        <el-table-column
        prop="adType"
        v-if="witchChecked.four"
        label="广告类型"
        >
        </el-table-column>
        <el-table-column
        prop="uv_total"
        label="请求用户数"
        >
        </el-table-column>
        <el-table-column
        prop="request_total"
        label="广告请求"
        >
        </el-table-column>
        <el-table-column
        prop="request_back"
        label="请求成功数"
        >
        </el-table-column>
        <el-table-column
        prop="request_rate"
        label="填充率"
        >
        </el-table-column>
        <el-table-column
        prop="show_total"
        label="曝光数"
        >
        </el-table-column>
        <el-table-column
        prop="show_rate"
        label="曝光率"
        >
        </el-table-column>
        <el-table-column
        prop="click_total"
        label="点击数"
        >
        </el-table-column>
        <el-table-column
        prop="click_rate"
        label="点击率"
        >
        </el-table-column>
        <el-table-column
        prop="cpm"
        label="eCPM（元）"
        >
        </el-table-column>
         <el-table-column
        prop="money_total"
        label="预计收入（元）"
        >
        </el-table-column>
    </el-table>
</div>
</template>

<script>
  import { reportData } from '../api/staticPages'
  import { Loading } from 'element-ui'
  import Paging from '../components/Paging.vue'
  import FileSaver from 'file-saver'
  import XLSX from 'xlsx'
  export default {
    props:{
      isFilter:{
        type:Boolean,
        default:true
      },
      tableData:{
        type:Array,
      },
      total:{
        type:Number,
        default:10
      },
      returnPage1:{
        type:Number,
      },
      reqData:{
        type:Object
      }
    },
    components:{
      Paging
    },
    data() {
      return {
        witchChecked:{
          one:true,
          two:false,
          three:false,
          four:false
        },
        allTableData:null
      }
    },
    computed:{
      newTableData(){
        let newList = [...this.tableData.slice()]
        newList.forEach(item=>{
          let num1 = item.show_total.split(',').join('')
          let num2 = item.request_back.split(',').join('')
          if(num2 == '0'){
            item.show_rate = 0
          }else{
            item.show_rate = (num1/num2*100).toFixed(2)+'%'
          }
        })
        return newList
      }
    },
    methods: {
      handleChangeDeep(){
        let {witchChecked} = this
        let arr = []
        witchChecked.two && arr.push('medium')
        witchChecked.three && arr.push('advplace')
        witchChecked.four && arr.push('showType')
        this.$emit('passGroupBy',arr)
        sessionStorage.setItem('groupBy',JSON.stringify(arr))
      },
      changeSize(val){
        this.$emit('changeSize',val)
      },
      changePage(val){
        this.$emit('changePage',val)
      },
      exportExcel() {
        let loadingInstance = Loading.service({
            lock: true,
            text: '',
            spinner: 'el-icon-loading',
            background: 'rgba(165, 165, 220, .1)'
        })
        reportData({...this.reqData,isall:1}).then(res=>{
            let newList = [...res.data.data.lists.slice()]
            newList.forEach(item=>{
              let num1 = item.show_total.split(',').join('')
              let num2 = item.request_back.split(',').join('')
              if(num2 == '0'){
                item.show_rate = 0
              }else{
                item.show_rate = (num1/num2*100).toFixed(2)+'%'
              }
            })
            loadingInstance.close()
            this.allTableData = newList
            let timer = setTimeout(()=>{
              var wb = XLSX.utils.table_to_book(document.querySelector('#getout__table'))
              var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
              try {
                  FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'sheetjs.xls')
              } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
              clearTimeout(timer)
              return wbout
            },100)
        }).catch(err=>{
            loadingInstance.close()
        })
        
      },
    },
    created(){
      let checkedGroup = sessionStorage.getItem('groupBy')
      if(checkedGroup){
        let {witchChecked} = this
        JSON.parse(checkedGroup).forEach(item=>{
          if(item==='medium'){witchChecked.two = true}
          if(item==='advplace'){witchChecked.three = true}
          if(item==='showType'){witchChecked.four = true}
        })
      }
    }
  }
</script>
<style lang="less" scoped>
    .dataRow{
      .margin-tb{
        font-size: 14px;
        span{
          color: #333;
          font-weight: 900;
        }
      }
    }
    .downLoadBtn{
      .el-button--small{
        color: #989899 !important;
      }
    }
    .alignRight{
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
</style>